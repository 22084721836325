<template>
  <div
    id="report"
    class="report"
  >
    <div class="row">
      <div class="col">
        <div class="col-item info">
          <div class="label pic">
            <img
              v-if="result.evaluationLevel"
              :src="require(`./images/level/${result.evaluationLevel}.svg`)"
            >
          </div>
          <div class="value bold level">
            <div>测评等级</div>
            <div class="level-tag red">
              {{ result.evaluationLevel }}
            </div>
          </div>
        </div>
        <div class="col-item info">
          <div class="label">
            测评宝宝姓名
          </div>
          <div class="value">
            {{ result.name }}
          </div>
        </div>
        <div class="col-item info">
          <div class="label">
            测评宝宝年龄
          </div>
          <div class="value">
            {{ result.evalAge }}
          </div>
        </div>
        <div class="col-item info">
          <div class="label">
            测评时间
          </div>
          <div class="value">
            {{ result.evalDate }}
          </div>
        </div>
      </div>
      <div class="col">
        <h3 class="title">
          报告解读
        </h3>
        <div
          class="content"
          v-html="result.reportInterpretation"
        />
      </div>
      <div class="col contact">
        <h3 class="title">
          专家建议
        </h3>
        <div
          v-for="(item, index) in telPhoneInfo"
          :key="index"
          class="col-item tel"
        >
          <div class="label">
            {{ item.dataName }}
          </div>
          <div class="value">
            {{ item.dataVal }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3 class="title">
          宝宝身高得分
        </h3>
        <div class="chart">
          <Chart :option="chartOfHeigth" />
        </div>
      </div>
      <div class="col">
        <h3 class="title">
          宝宝体重得分
        </h3>
        <div class="chart">
          <Chart :option="chartOfWeight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEvalResult, telPhone, growthStandardData, evalRecord
} from '@/api';
import chartOptions from './testData';
import chartOptions1 from './testData1';

export default {
  components: {
    Chart: () => import('@/components/Chart/Echart.vue')
  },
  data() {
    return {
      result: {},
      telPhoneInfo: {},
      chartOfHeigth: chartOptions,
      chartOfWeight: chartOptions1,
    };
  },
  computed: {
    evalRecordId() {
      const { evalRecordId } = this.$route.query;
      return /\d/.test(evalRecordId) ? Number(evalRecordId) : undefined;
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.getEvalResult();
      this.telPhone();
      await this.growthStandardData('HEIGHT_STANDARD');
      await this.growthStandardData('WEIGHT_STANDARD');
      this.evalRecord();
    },
    // 获取测评结果
    getEvalResult() {
      const params = {
        evalRecordId: this.evalRecordId
      };
      getEvalResult(params).then((res) => {
        this.result = res.data || {};
        this.$store.commit('report/SET_TITLE_CHILD_NAME', this.result.name);
      }, (err) => {
        this.$message.warning(err.msg || '获取测评结果失败！');
      });
    },
    // 联系方式
    telPhone() {
      const params = {
        phone: localStorage.getItem('phone')
      };
      telPhone(params).then((res) => {
        this.telPhoneInfo = res.data || {};
      }, (err) => {
        this.telPhoneInfo = {};
      });
    },
    // tab切换
    tabChange(index, item) {
      this.tabIndex = index;
      this.growthStandardData(item.value);
    },
    // 成长标准数值
    growthStandardData(growthClass) {
      const params = {
        sex: this.$route.query.childrenSex,
        growthClass
      };
      return growthStandardData(params).then((res) => {
        if (!(res.data || []).length) return;
        const month = [];
        const series = [];
        const keys = {
          threeRd: 3,
          tenTh: 10,
          twentyFiveTh: 25,
          fiftyTh: 50,
          seventyFiveTh: 75,
          ninetyTh: 90,
          ninetySevenTh: 97,
        };
        res.data.forEach((item) => {
          month.push(item.monthAge);
          Object.keys(keys).forEach((key, index) => {
            if (!series[index]) {
              this.$set(series, index, {
                name: keys[key],
                type: 'line',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 0.5
                    }
                  }
                },
                data: []
              });
            }
            series[index].data.push(item[key]);
          });
        });
        const chart = growthClass === 'HEIGHT_STANDARD' ? this.chartOfHeigth : this.chartOfWeight;
        chart.xAxis[0].data = month;
        chart.series = series;
      });
    },
    evalRecord() {
      const params = {
        id: this.$route.query.id
      };
      evalRecord(params).then((res) => {
        const list = (res.data?.evalRecords || []);
        if (!list.length) return;
        const height = [];
        const weight = [];
        list.forEach((item) => {
          height.push(item.height);
          weight.push(item.weight);
        });
        this.chartOfHeigth.series.push({
          name: '身高',
          type: 'line',
          smooth: true,
          data: height
        });
        this.chartOfWeight.series.push({
          name: '体重',
          type: 'line',
          smooth: true,
          data: weight
        });
      });
    }
  },
};
</script>

<style lang="less" scoped>
.report{
  display: flex;
  height: 100%;
  width: 874px;
  padding: 16px;
  box-sizing: border-box;
  margin: 0 auto;
  justify-content: space-between;

  .pic,.pic>img{
    width: 75px;
    height: 75px;
  }

  .row{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 413px;
    &:nth-child(2){
      .col{
        height: calc(50% - 8px);
        display: flex;
        flex-direction: column;
      }
      .chart{
        flex: 1;
      }
    }
  }
  .border{
    border: 1px solid #E5E5E5;
    border-radius: 4px;
  }
  .col{
    padding: 16px;
    .border;
    &.contact{
      height: 146px;
    }

  }
  .col+.col{
    margin-top: 16px;
  }
  .title{
    font-size: 16px;
    margin: 0 auto 16px;
    text-align: center;
    color: #333;
  }
  // 左上角
  .col-item{
    display: flex;
    font-size: 14px;
    &:first-child{
      margin-bottom: 10px;
    }
    &:not(:first-child){
      margin-bottom: 10px;
    }
    .label{
      color:#333;
    }
    .value{
      color: #000;
    }
    &.info{
      .label{
        width: 120px;
      }
      .value{
        flex: 1;
      }
    }
    &.tel{
      .label{
        flex: 1;
      }
      @primary: #5FB4BF;
      .value{
        color: @primary;
        cursor: pointer;
        &:active{
            color: darken(@primary,5%);
        }
      }
    }
    .level{
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-tag{
        font-size: 18px;
        margin-top: 8px;
      }
      .red{
        color: #FF3730;
      }
    }
  }
  .content{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    overflow: auto;
    height: 160px;
  }
  .chart{
    min-height: 200px;
    position: relative;
    .tab{
      position: absolute;
      left: 0;
      top: -40px;
      border-radius: 4px;
      border: 1px solid #5FB4BF;
      overflow: hidden;
      display: flex;
      z-index: 999;
      &-item{
        padding: 8px 20px;
        cursor: pointer;
        &.active{
          background: #5FB4BF;
          color:white;
        }
      }
    }
    // background: palegoldenrod;
  }
}
</style>
