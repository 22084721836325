export default {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  grid: {
    left: 30,
    right: 30,
    bottom: 20,
    top: 30
  },
  xAxis: [
    {
      type: 'category',
      name: '月',
      boundaryGap: false,
      axisLine: {
        symbol: ['none', 'arrow']
      },
      data: []
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'cm',
      scale: true,
      axisLine: {
        symbol: ['none', 'arrow']
      },
    }
  ],
  series: []
};

/*

      const chart = this.tabs[this.tabIndex];
      const month = [];
      const series = [
        {
          name: 'threeRd',
          data: [],
          smooth: true
        },
        {
          name: 'tenTh',
          data: [],
          smooth: true
        },
        {
          name: 'twentyFiveTh',
          data: [],
          smooth: true
        },
        {
          name: 'fiftyTh',
          data: [],
          smooth: true
        },
        {
          name: 'seventyFiveTh',
          data: [],
          smooth: true
        },
        {
          name: 'ninetyTh',
          data: [],
          smooth: true
        },
        {
          name: 'ninetySevenTh',
          data: [],
          smooth: true
        },
      ];
      list.forEach((item) => {
        month.push(item.monthAge);
        series.forEach((serie, index) => {
          series[index].data.push(item[serie.name]);
        });
      });
      console.log(series, month);
*/
