export default {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  grid: {
    left: 30,
    right: 30,
    bottom: 20,
    top: 30
  },
  xAxis: [
    {
      type: 'category',
      name: '月',
      boundaryGap: false,
      axisLine: {
        symbol: ['none', 'arrow']
      },
      data: []
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'kg',
      axisLine: {
        symbol: ['none', 'arrow']
      },
    }
  ],
  series: []
};
